<template>
    <v-img
      class="align-middle"
      :src="logo"
      :width="size"
      transition="scale-transition"
    />
</template>

<script>
export default {
  name: 'Favicon',
  props: {
    size: {
      type: String,
      default: '40',
    },
  },
  computed: {
    logo() {
      // eslint-disable-next-line global-require
      return require('@/assets/logo_pf.png');
    },
  },
 };
</script>
