<template>
  <div>
    <h1
      class="text-center"
    >
      Offline...
    </h1>
    <br>
    <div class="wobble">
      <Favicon
        size="100"/>
    </div>
  </div>
</template>

<script>
import Favicon from '@/components/Favicon.vue';

export default {
  name: 'RotatingLogo',
  components: {
    Favicon,
  },
};
</script>

<style scoped>
.wobble {
  text-align: center;
  -webkit-animation: rotateY 2s infinite linear;
}

@-webkit-keyframes rotateY {
  to { -webkit-transform: rotateY(360deg); }
}

@keyframes rotateY {
  to { transform: rotateY(360deg); }
}
</style>
